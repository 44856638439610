import { createSignal, Show, } from "solid-js";
import { Stack,  Grid, Divider,  } from "@suid/material";
import { PageListMenu } from '../SmallComponents/SmallListMenu';
import { MenuItemType } from "../../../utils/menuUtil"
import {MainListComponent} from '../SmallComponents/SmallListComponent'
import { getText } from '../SmallComponents/Translate'

interface CustomerJobMenu {
    canAdd?: boolean;
    canEdit?: boolean;
    canDelete?: boolean;
    canSelect?: boolean;
}


const CandidateJobMenu = () => {

    const [openFilter, setOpenFilter] = createSignal(false);

    const handleOpenFilter = () => {
        setOpenFilter(true);
    }
    const handleCloseFilter = () => {
        setOpenFilter(false);
    }


    const jobMenu: MenuItemType[] = [
        { actionType: "select", value: "selectjob", label: "Select Job", icon: "select", itemType: "action", handleMenuClick: handleOpenFilter },
          
        { value: "d1", label: "", itemType: "divider", routeName: "" },
        { actionType: "other", value: "openroles", label: "Show other open roles for same job", itemType: "switch", routeName: "" },
        { actionType: "other", value: "openroles", label: "Show co-workers for same job", itemType: "switch", routeName: "" },
    ]; 

    const txtHeaderactive = getText("jobpage", "headeractive")
    const txtJobVisible = getText("jobpage", "jobvisible")

    return (
        <>
            <Grid container   >
                <Grid item xs={12} md={6}  >

                    <Show
                        when={openFilter() === true}>
                        <MainListComponent openAsDialog={openFilter()} defaultFilterValue="active" type="customerjob" handleCloseEvent={handleCloseFilter} />
                    </Show>

                    <Stack direction="row" justifyContent="space-between"  >
                     
                        <PageListMenu options={jobMenu} handleEvent={handleOpenFilter} />
                     
                        {/* Addition navigation options for the customer 
                        
                          <MainLeftButtons />
                        */}
                      
                     

                    </Stack>

                </Grid>
                <Grid item xs={12} md={6} >
                    <Stack direction="row" justifyContent="right" alignContent="center" margin={1} spacing={1}>
                        
                        {/* Present Candidates account status 
                        
                        <Tags />

                        */}

                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>
        </>
    );
}

export { CandidateJobMenu }